<template>
  <AtomsDatepicker
    ref="birthdate"
    v-model="currValue"
    v-bind="{
      ...props,
      ...$attrs
    }"
    type="date"
    model-type="date"
    :disable-date="disableDate"
    :disable-datepicker="disableDatepicker"
    @update="handleInput"
  />
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

import dayjs from 'dayjs'

import { validateAge } from '~/helpers/validator'

defineOptions({
  name: 'MoleculesBirthdate',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },

  type: {
    type: String,
    default: 'date',
    validator: value => ['date', 'datetime', 'year', 'month', 'time', 'week', 'birthdate'].includes(value)
  },

  format: {
    type: String,
    default: 'DD/MM/YYYY'
  },

  valueType: {
    type: String,
    default: 'format'
    // date, timestamp, format, token(MM/DD/YYYY)
  },

  label: {
    type: String,
    default: '',
    required: true
  },

  errorMessage: {
    type: String,
    default: ''
  },

  isSuccess: {
    type: Boolean,
    default: false
  },

  validAge: {
    type: Array,
    default: () => [],
    validator: arr => {
      const age1 = arr?.[0]
      const age2 = arr?.[1]

      return !(age1 && age2 && (age2 < age1))
    }
  },

  minDate: {
    type: String,
    default: ''
  },

  maxDate: {
    type: String,
    default: ''
  },

  disableDatepicker: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'age',
  'update',
  'update:modelValue'
])

const $attrs = useAttrs()

const currValue = proxyModel(props, 'modelValue')
const age = ref(0)

onMounted(() => {
  if (currValue.value) {
    handleInput(currValue.value)
  }
})

function handleInput (value) {
  emit('update', value)

  if (!value) {
    return
  }

  const date = value.split('/')
  const birthdate = `${date[1]}/${date[0]}/${date[2]}`
  const year = date[2]

  const now = dayjs()

  age.value = (() => {
    if (!date.every(item => item) || (year && (year.length !== 4 || Number(year.substring(0, 1)) === 0))) {
      return 0
    }

    return Math.floor(now.diff(dayjs(birthdate), 'year'))
  })()

  emit('age', age.value)
}

function disableDate (date) {
  if (
    _isEmpty(props.validAge) &&
    !_isEmpty(props.minDate) &&
    !_isEmpty(props.maxDate)
  ) {
    return date < new Date(props.minDate) || date > new Date(props.maxDate)
  }

  if (_isEmpty(props.validAge)) {
    return false
  }

  date = dayjs(date)

  const now = dayjs()
  const ageTemp = Math.floor(now.diff(dayjs(date), 'year'))
  return !validateAge(ageTemp, props.validAge)
}
</script>
